<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <simple-table
                    :tableName="'Azure LargeFaceList'"
                    :rows="itemsLists"
                    :columns="headersTable"
                    :perPage="[10, 25, 50, 100]"
                    shortPagination
                >
                    <template slot="actionButtonsLeft">
                        <v-btn
                            class="mr-2"
                            outlined
                            fab
                            small
                            color="teal"
                            @click="abrirModalInformativo()"
                        >
                            <v-icon>mdi-information-variant</v-icon>
                        </v-btn>
                        <div class="botonAgregar" style="padding-right: 12px;">
                            <v-btn
                                rounded
                                color="#004BAF"
                                class="btnAdd"
                                right
                                @click="abrirModal('add')"
                            >
                                <v-icon dark class="mr-2">add</v-icon>Agregar
                                <div class="mr-4"></div>
                            </v-btn>
                        </div>
                    </template>
                    <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                        <tr :key="item.id" v-for="(item, index) in data">
                            <td><div>{{item.largeFaceListId}}</div></td>
                            <td><div>{{item.name}}</div></td>
                            <td><div>{{item.userData}}</div></td>
                            <td>
                                <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="mostrarDetalle(item)">
                                                <v-icon 
                                                    v-text="'$visibility_outline'"
                                                    class="iconoDelgadoBoton"
                                                ></v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Rostros empleados</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" @click="eliminar(item)" class="botonHover" icon fab dark small>
                                                <v-icon class="material-icons tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </simple-table>
            </v-row>

            <v-dialog v-model="dialog" persistent max-width="500px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <v-card class="borde-card">
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoadingModal">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="LargeFaceListId" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="LargeFaceListId" 
                                                class="pa-0 ma-0" 
                                                v-model="largeFaceList.largeFaceListId" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Nombre" 
                                                class="pa-0 ma-0" 
                                                v-model="largeFaceList.name" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Empresa" 
                                                class="pa-0 ma-0" 
                                                v-model="largeFaceList.userData" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardar()" 
                                :disabled="invalid" 
                                :loading="isSaving"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogInformativo" persistent max-width="500px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalInformativo"></v-btn>
                </template>
                <v-card class="borde-card">
                    <v-card-title class="justify-center card_titulo">
                        <div class="headerModal">
                            <h2 class="titleModal">{{ "Cognitive Services - Face API" }}</h2>
                        </div>
                    </v-card-title>

                    <v-card-text class="card_texto">                            
                        <v-container grid-list-md id="contenedor">
                            <v-divider class="divider"></v-divider>
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0 mb-4">
                                    <h3>
                                        Large Face List
                                    </h3>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                    <a href="https://learn.microsoft.com/en-us/rest/api/faceapi/large-face-list " target="_blank">
                                        https://learn.microsoft.com/en-us/rest/api/faceapi/large-face-list   
                                    </a>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" class="pt-4 pb-0 ma-0">
                                    <a href="https://westus.dev.cognitive.microsoft.com/docs/services/563879b61984550e40cbbe8d/operations/563879b61984550f30395238" target="_blank">
                                        https://westus.dev.cognitive.microsoft.com/docs/services/563879b61984550e40cbbe8d/operations/563879b61984550f30395238 
                                    </a>
                                </v-col>
                                
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end card_accion">
                        <button
                            class="btnCerrar"
                            @click="cerrarModalInformativo()"
                        >
                            Cerrar
                        </button>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import DataTableSimple from '@/components/simpletable/Datatable.vue';
import AzureCognitiveService from "@/api/cognitiveServices";
import Notify from '@/plugins/notify';

export default {
    components: {
        "simple-table": DataTableSimple,
    },
    data() {
        return {
            datosLogin      : null,
            rol             : null,
            accion          : '',
            itemsLists      : [],
            headersTable    : [
                {
                    label: 'LargeFaceListId',
                    name: 'largeFaceListId',
                    filterable: false
                },
                {
                    label: 'Nombre',
                    name: 'name',
                    filterable: false
                },
                {
                    label: 'Empresa',
                    name: 'userData',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            isLoadingModal: false,
            largeFaceList: {
                largeFaceListId: null,
                name: null,
                userData: null
            },
            dialog              : false,
            isSaving            : false,
            isLoading           : false,

            dialogInformativo   : false,
        }
    },
    watch:{

    },
    methods:{
        getLargeFaceLists(){
            AzureCognitiveService.getLargeFaceLists(this.cliente).then((response) => {
                this.itemsLists = response
            })
            .catch(err => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        eliminar(data){
            Notify.Alert(
                "¿Estás seguro de eliminar este LargeFaceList?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del proceso de reconocimiento facial.",
                "Eliminar",
                () => {
                    AzureCognitiveService.deleteLargeFaceList(data.largeFaceListId).then(response => {
                        Notify.Success("Operación exitosa", "La LargeFaceList ha sido eliminado satisfactoriamente");
                        this.getLargeFaceLists();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        abrirModal(accion, data = null){
            this.accion = accion;
            this.isLoadingModal  = true;
            if(accion == "add"){
                this.tituloModal    = "Registrar LargeFaceList";
                this.imagen         = "/static/modal/brigadasCreate.svg";
                
            } else {
                this.tituloModal                    = "Actualizar LargeFaceList";
                this.imagen                         = "/static/modal/brigadasUpdate.svg";
                this.largeFaceList.largeFaceListId  = data.largeFaceListId;
                this.largeFaceList.name             = data.name;
                this.largeFaceList.userData         = data.userData;
            }
            this.isLoadingModal  = false;
            setTimeout(() => { document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal(){
            this.isLoadingModal= false;
            this.largeFaceList= {
                largeFaceListId: null,
                name: null,
                userData: null
            };
            this.dialog= false;
            this.isSaving= false;
        },
        guardar(){
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                let parametros = {
                    name: this.largeFaceList.name,
                    userData: this.largeFaceList.userData
                }

                this.isLoading = true;
                if(this.accion === "add") {
                    AzureCognitiveService.createLargeFaceLists(this.largeFaceList.largeFaceListId, parametros).then((response) => {
                        Notify.Success("Operación exitosa", "La LargeFaceLists se guardó satisfactoriamente.");
                        this.getLargeFaceLists();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    AzureCognitiveService.updateLargeFaceLists().then((response) => {
                        Notify.Success("Operación exitosa", "La LargeFaceLists se actualizó satisfactoriamente.");
                        this.getLargeFaceLists();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        abrirModalInformativo(){
            this.dialogInformativo = true;
        },
        cerrarModalInformativo(){
            this.dialogInformativo = false;
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
        mostrarDetalle(item){
            this.$session.remove("largeFaceList");
            this.$session.set('largeFaceList',item);
            this.$router.push('/azureRostros');
        }
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.getLargeFaceLists();
    },
}
</script>
<style scoped>
#btnModalInformativo{
    display: none;
}
</style>